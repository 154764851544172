import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// instances
import { connector } from '../instances/wallet-connect';
import { metamaskProvider, signer, web3 } from '../instances/ethers';

// apis
import ContractApi from '../api/contract.api';

// helpers
import sessionCheck from '../helpers/session-check';
import contractActivityCheck from '../helpers/contract-activity-check';

export const ConnectionType = {
  WalletConnect: 'WalletConnect',
  Metamask: 'Metamask',
};

const initialState = {
  currentModal: null,
  currentMintSide: '',

  totalSupplyPigeon: null,
  totalSupplyDog: null,
  pigeonPaused: null,
  dogPaused: null,

  tokensList: [],
  currentToken: null,
  transactionHash: null,

  session: {
    type: null,
    address: null,
    isMainNet: null,
  },

  forceBlock: false,
  loading: null,
  loadingTokenList: null,
  error: null,
};

export const MintProcess = {
  close: null,
  mint: 'MINT_MODAL',
  wallet: 'WALLET_MODAL',
  success: 'SUCCESS_MODAL',
};

export const MintType = {
  Pigeon: 'Pigeon',
  Dog: 'Dog',
};

export const requestTokensList = createAsyncThunk(
  'client/requestTokenList',
  async (_, slice) => {
    const state = slice.getState();
    const owner = state.session.address;
    let tokens;

    try {
      const limit = await ContractApi.getBalanceOf(owner);
      tokens = await ContractApi.getTokensOfOwner(owner, 0, limit);
    } catch (e) {
      const msg = e.error?.data?.originalError?.message || e.reason;
      if (msg) {
        throw new Error(msg);
      } else {
        throw e;
      }
    }

    tokens = tokens.map(Number);

    if (state.currentMintSide === MintType.Dog) {
      return tokens.filter(e => 3000 < e && e <= 6000);
    } else if (state.currentMintSide === MintType.Pigeon) {
      return tokens.filter(e => e <= 3000);
    }
  },
);

export const requestTokenData = createAsyncThunk(
  'client/requestTokenData',
  async () => {
    const chainId = (await web3.getNetwork()).chainId;

    if (metamaskProvider && chainId !== +process.env.REACT_APP_MAIN_NET) {
      throw new Error();
    }

    return {
      totalSupplyPigeon: await ContractApi.getPigeonTotalSupply(),
      totalSupplyDog: await ContractApi.getDogTotalSupply(),
      pigeonPaused: await ContractApi.checkIsPigeonContractPaused(),
      dogPaused: await ContractApi.checkIsDogContractPaused(),
    };
  },
);

export const intervalRequestTokenData = createAsyncThunk(
  'client/intervalRequestTokenData',
  async () => {
    const chainId = parseInt(web3.provider.chainId, 16);

    if (metamaskProvider && chainId !== +process.env.REACT_APP_MAIN_NET) {
      throw new Error();
    }

    return {
      totalSupplyPigeon: await ContractApi.getPigeonTotalSupply(),
      totalSupplyDog: await ContractApi.getDogTotalSupply(),
      pigeonPaused: await ContractApi.checkIsPigeonContractPaused(),
      dogPaused: await ContractApi.checkIsDogContractPaused(),
    };
  },
);

export const loadConnectionData = createAsyncThunk(
  'client/loadConnectionData',
  async () => {
    const session = {
      type: null,
      address: null,
      chainId: null,
    };

    if (connector.provider.connected) {
      session.type = ConnectionType.WalletConnect;
      session.address = connector.provider.accounts[0];
      session.chainId = +process.env.REACT_APP_MAIN_NET;
    } else {
      session.type = ConnectionType.Metamask;

      try {
        session.address = await signer.getAddress();
      } catch (e) {
        console.error(e.message);
      }

      session.chainId = parseInt(web3.provider.chainId, 16);
    }

    return session;
  },
);

export const connectMetamask = createAsyncThunk(
  'client/connectMetamask',
  async () => {
    if (!metamaskProvider) {
      throw new Error('Please install metamask to use this app.');
    }

    return (await metamaskProvider.request({ method: 'eth_requestAccounts' }))[0];
  },
);

export const connectLedger = createAsyncThunk(
  'client/connectLedger',
  async () => {
    if (connector.provider.connected) {
      await connector.provider.disconnect();
    }

    await connector.provider.connect();
    return connector.provider.accounts[0];
  },
);

export const mintWithPathId = createAsyncThunk(
  'client/mintWithPathId',
  async ({
    spender,
    tokenId,
  }, slice) => {
    const state = slice.getState();
    const conType = state.session.type;
    const address = state.session.address;

    try {
      if (!(await ContractApi.isApprovedOrOwner(conType, spender, tokenId))) {
        await ContractApi.setApprovalForAll(conType, address, spender);
      }

      return await ContractApi.contractMint(
        conType,
        address,
        tokenId,
        spender,
      );
    } catch (e) {
      const msg = e.error?.data?.originalError?.message || e.reason;
      if (msg) {
        throw new Error(msg);
      } else {
        throw e;
      }
    }
  },
);

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clearTokensList: (state) => {
      state.tokensList = [];
    },
    handleChangeCurrentToken: (state, action) => {
      state.currentToken = action.payload;
    },
    handleAddressChange: (state, action) => {
      if (action.payload.type === state.session.type) {
        state.session = {
          type: state.session.type,
          address: action.payload.address,
          isMainNet: state.session.isMainNet,
        };
      }
    },
    handleChainChange: (state, action) => {
      if (action.payload.type === state.session.type) {
        state.session = {
          type: state.session.type,
          address: state.session.address,
          isMainNet: action.payload.isMainNet
            ?? parseInt(action.payload.chainId, 16) === +process.env.REACT_APP_MAIN_NET,
        };
      }
    },
    handleDisconnect: (state, action) => {
      if (action.payload === state.session.type) {
        state.session = {
          type: null,
          address: null,
          isMainNet: true,
        };
      }
    },
    openMintModal(state, action) {
      const {
        session: {
          isMainNet,
          address,
        },
        forceBlock,
        pigeonPaused,
        dogPaused,
      } = state;
      let check = sessionCheck(isMainNet && !forceBlock, address, metamaskProvider);

      if (!check) {
        check = contractActivityCheck(action.payload, {
          pigeonPaused,
          dogPaused,
        });
      }

      if (!check) {
        state.currentMintSide = action.payload;
        state.currentModal = MintProcess.mint;
      } else {
        state.error = check;
        state.currentModal = MintProcess.close;
      }
    },
    openWalletModal(state) {
      state.currentModal = MintProcess.wallet;
    },
    closeCurrentModal(state) {
      state.currentModal = MintProcess.close;
      state.transactionHash = null;
    },
    closeErrorModal: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(requestTokensList.pending, (state) => {
      state.loadingTokenList = true;
    });
    builder.addCase(requestTokensList.fulfilled, (state, action) => {
      state.tokensList = action.payload;

      state.loadingTokenList = false;
    });
    builder.addCase(requestTokensList.rejected, (state, action) => {
      state.loadingTokenList = false;
      state.error = action.error.message;
    });

    builder.addCase(requestTokenData.fulfilled, (state, action) => {
      const {
        totalSupplyPigeon,
        totalSupplyDog,
        pigeonPaused,
        dogPaused,
      } = action.payload;

      state.totalSupplyPigeon = totalSupplyPigeon;
      state.totalSupplyDog = totalSupplyDog;
      state.pigeonPaused = pigeonPaused;
      state.dogPaused = dogPaused;
    });

    builder.addCase(intervalRequestTokenData.fulfilled, (state, action) => {
      const {
        totalSupplyPigeon,
        totalSupplyDog,
        pigeonPaused,
        dogPaused,
      } = action.payload;

      state.totalSupplyPigeon = totalSupplyPigeon;
      state.totalSupplyDog = totalSupplyDog;
      state.pigeonPaused = pigeonPaused;
      state.dogPaused = dogPaused;
    });

    builder.addCase(loadConnectionData.fulfilled, (state, action) => {
      const address = action.payload.address;
      const isMainNet = action.payload.chainId === +process.env.REACT_APP_MAIN_NET;

      state.session = {
        type: action.payload.type,
        address,
        isMainNet,
      };

      if (!isMainNet) {
        state.forceBlock = true;
        state.error = `You are using the wrong network, please switch to
        ${process.env.REACT_APP_MAIN_NET_NAME} and reload the page.`;
      }
    });

    builder.addCase(connectMetamask.pending, state => {
      state.loading = true;
      state.currentModal = MintProcess.close;
    });
    builder.addCase(connectMetamask.fulfilled, (state, action) => {
      state.session = {
        type: ConnectionType.Metamask,
        address: action.payload,
        isMainNet: web3.network.chainId === +process.env.REACT_APP_MAIN_NET,
      };

      state.loading = false;
    });
    builder.addCase(connectMetamask.rejected, (state, action) => {
      state.error = action.error.message || 'Error occurred while connecting via metamask';
      state.loading = false;
    });

    builder.addCase(connectLedger.pending, state => {
      // state.loading = true;
      state.currentModal = MintProcess.close;
    });
    builder.addCase(connectLedger.fulfilled, (state, action) => {
      state.session = {
        type: ConnectionType.WalletConnect,
        address: action.payload,
        isMainNet: true,
      };

      // state.loading = false;
    });
    builder.addCase(connectLedger.rejected, (state, action) => {
      state.error = action.error.message || 'Error occurred while connecting via wallet connect';
      // state.loading = false;
    });

    builder.addCase(mintWithPathId.pending, (state) => {
      state.loading = true;
      state.currentModal = MintProcess.close;
      state.currentToken = null;
    });
    builder.addCase(mintWithPathId.fulfilled, (state, action) => {
      state.loading = false;
      state.currentModal = MintProcess.success;
      state.transactionHash = action.payload;
    });
    builder.addCase(mintWithPathId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default clientSlice.reducer;

export const {
  clearTokensList,
  handleAddressChange,
  handleChainChange,
  handleDisconnect,
  handleChangeCurrentToken,
  openMintModal,
  openWalletModal,
  closeCurrentModal,
  handleChangeAddress,
  closeErrorModal,
} = clientSlice.actions;

export const selectCurrentModal = state => state.currentModal;
export const selectCurrentMintSide = state => state.currentMintSide;
export const selectAddress = state => state.session.address;
export const selectTotalSupplyDog = state => state.totalSupplyDog;
export const selectTotalSupplyPigeon = state => state.totalSupplyPigeon;
export const selectTokensList = state => state.tokensList;
export const selectLoading = state => state.loading;
export const selectError = state => state.error;
export const selectCurrentToken = state => state.currentToken;
export const selectLoadingTokenList = state => state.loadingTokenList;
export const selectTransactionHash = state => state.transactionHash;
