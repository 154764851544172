import { useDispatch, useSelector } from 'react-redux';
import { CircleLoader } from 'react-spinners';

import {
  openWalletModal,
  selectAddress,
  selectTotalSupplyDog,
  selectTotalSupplyPigeon,
} from '../../../store/client';

import LOGO from '../../../assets/images/logo.gif';

import StyledButton from '../../common/StyledButton/StyledButton';

import styles from './Header.module.css';


export default function Header() {
  const address = useSelector(selectAddress);
  const totalSupplyDog = useSelector(selectTotalSupplyDog);
  const totalSupplyPigeon = useSelector(selectTotalSupplyPigeon);

  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(openWalletModal());
  };

  return (
    <header className={styles.header}>
      <div className={styles.content_container}>
        <div className={styles.left}>
          <img className={styles.logo} src={LOGO} alt="" width={62} height={60}/>
          <span className={styles.logo_description}>The Gutter Cat Gang is growing!</span>
        </div>
        <div className={styles.right}>
          {(totalSupplyDog !== null && totalSupplyPigeon !== null)
            ? <div className={styles.counters}>
              <span className={styles.counter}>{totalSupplyPigeon} Pigeons Redeemed</span>
              <span className={styles.counter}>{totalSupplyDog} Dogs Redeemed</span>
            </div>
            : <CircleLoader color="#D24CFBFF" size={25} css="margin: 1rem" />}
          <StyledButton
            className={styles.con_wal_btn}
            handleClick={address ? null : openModal}
          >
            {address ? address.slice(0, 6) + '...' + address.slice(-4) : 'Connect Wallet'}
          </StyledButton>
        </div>
      </div>
    </header>
  );
}
