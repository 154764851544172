import classNames from 'classnames';

// styles
import styles from './StyledRadio.module.css';

export default function StyledRadio(
  {
    children,
    className,
    handleChange,
    ...props
  }) {
  return (
    <label className={classNames(styles.styled_checkbox, className)}>
      <input
        {...props}
        type="radio"
        className={styles.checkbox_input}
        onChange={handleChange}
      />
      <span className={styles.inner_wrapper}>
        <span className={styles.checkmark}/>
        <span className={styles.label}>{children}</span>
      </span>
    </label>
  );
}
