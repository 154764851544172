import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CircleLoader } from 'react-spinners';

import {
  MintType,
  clearTokensList,
  handleChangeCurrentToken,
  mintWithPathId,
  requestTokensList,
  selectCurrentMintSide,
  selectCurrentToken,
  selectLoadingTokenList,
  selectTokensList,
} from '../../../../../store/client';

import StyledRadio from '../../../../common/StyledRadio/StyledRadio';
import StyledButton from '../../../../common/StyledButton/StyledButton';

import styles from './MintModal.module.css';

export default function MintModal() {
  const dispatch = useDispatch();

  const currentMintSide = useSelector(selectCurrentMintSide);
  const currentToken = useSelector(selectCurrentToken);
  const loadingTokenList = useSelector(selectLoadingTokenList);
  const tokensList = useSelector(selectTokensList);

  useEffect(() => {
    dispatch(requestTokensList());

    return () => {
      dispatch(clearTokensList());
    };
  }, [dispatch]);

  const confirmTransaction = () => {
    if (currentToken) {
      dispatch(mintWithPathId({ spender: currentMintSide, tokenId: currentToken }));
    }
  }

  const changeToken = (token) => () => {
    dispatch(handleChangeCurrentToken(token));
  }

  const wrapListElement = (el, index) => (
    <StyledRadio
      className={styles.token}
      name={'tokenList'}
      key={index}
      handleChange={changeToken(el)}
    >
      {el}
    </StyledRadio>
  );

  return (
    <>
      <p className={styles.info_text}>
        Redeem your Gutter Mint Pass for the new Gutter Species
      </p>

      <div className={styles.tokens_container}>
        {loadingTokenList
          ? <div className={styles.loader_wrapper}>
            <CircleLoader color="#D24CFBFF" size={35} />
          </div>
          : !tokensList.length
            ? <p className={styles.no_data}>{'You don\'t have any tokens'}</p>
            : tokensList.map(wrapListElement)}
      </div>

      <StyledButton
        className={styles.styled_button}
        handleClick={confirmTransaction}
        disable={!currentToken}
      >
        Select
      </StyledButton>
    </>
  );
}
