import { CircleLoader } from 'react-spinners';

// styles
import styles from './Loader.module.css';

export default function Loader() {
  return (
    <div className={styles.loader_overlay}>
      <CircleLoader color="#D24CFBFF" />
    </div>
  );
}
