import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { metamaskProvider } from './instances/ethers';
import { connector } from './instances/wallet-connect';
import {
  ConnectionType,
  handleAddressChange,
  handleChainChange,
  handleDisconnect,
  intervalRequestTokenData,
  loadConnectionData,
  requestTokenData,
  selectError,
  selectLoading,
} from './store/client';

import AppView from './Components/AppView/AppView';
import ErrorPopup from './Components/Popups/ErrorPopup/ErrorPopup';
import Loader from './Components/Popups/Loader/Loader';

function App() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  useEffect(() => {
    dispatch(requestTokenData());
    dispatch(loadConnectionData());

    const timerId = setInterval(() => {
      dispatch(intervalRequestTokenData());
    }, 30000);

    if (metamaskProvider) {
      metamaskProvider.on(
        'accountsChanged',
        ([address]) => dispatch(handleAddressChange({ address, type: ConnectionType.Metamask })),
      );
      metamaskProvider.on(
        'chainChanged',
        (chainId) => dispatch(handleChainChange({ chainId, type: ConnectionType.Metamask })),
      );
      metamaskProvider.on('disconnect', () => dispatch(handleDisconnect(ConnectionType.Metamask)));
    }

    if (connector.provider) {
      connector.provider.on('connect', (_, payload) => {
        const { accounts } = payload.params[0];

        dispatch(handleAddressChange({ address: accounts[0], type: ConnectionType.WalletConnect }));
        dispatch(handleChainChange({ isMainNet: true, type: ConnectionType.WalletConnect }));
      });
      connector.provider.on('disconnect', () => dispatch(handleDisconnect(ConnectionType.WalletConnect)));
    }

    return () => {
      clearInterval(timerId);

      if (metamaskProvider) {
        metamaskProvider.removeAllListeners('accountsChanged');
        metamaskProvider.removeAllListeners('chainChanged');
        metamaskProvider.removeAllListeners('disconnect');
      }

      if (connector.provider) {
        connector.provider.off('connect');
        connector.provider.off('disconnect');
      }
    };
  }, [dispatch]);

  return (
    <>
      <ErrorPopup isOpen={!!error} error={error} />
      {loading ? <Loader /> : null}
      <AppView/>
    </>
  );
}

export default App;
