// instances
import {
  dogContract,
  dogContractIFace,
  dogContractWithSigner,
  pigeonContract,
  pigeonContractIFace,
  pigeonContractWithSigner,
  speciesContract,
  speciesContractIFace,
  speciesContractWithSigner,
} from '../instances/contract';
import { connector } from '../instances/wallet-connect';
import { web3 } from '../instances/ethers';

// store
import { ConnectionType, MintType } from '../store/client';

const ContractApi = {
  async getDogTotalSupply() {
    const totalCount = await dogContract.tokenIndex();
    return +totalCount;
  },
  async getPigeonTotalSupply() {
    const totalCount = await pigeonContract.tokenIndex();
    return +totalCount;
  },
  async checkIsDogContractPaused() {
    return await dogContract.paused();
  },
  async checkIsPigeonContractPaused() {
    return await pigeonContract.paused();
  },
  async getBalanceOf(owner) {
    return await speciesContract.balanceOf(owner);
  },
  async getTokensOfOwner(owner, start, limit) {
    return await speciesContract.tokensOfOwner(owner, start, limit);
  },
  async isApprovedOrOwner(connectionType, spender, tokenId) {
    const address = spender === MintType.Dog
      ? process.env.REACT_APP_DOG_ADDRESS
      : process.env.REACT_APP_PIGEON_ADDRESS;
    return await speciesContract.isApprovedOrOwner(address, tokenId);
  },
  getContractWithSigner(contractType) {
    switch (contractType) {
    case MintType.Dog:
      return { contract: dogContractWithSigner, iface: dogContractIFace };
    case MintType.Pigeon:
      return { contract: pigeonContractWithSigner, iface: pigeonContractIFace };
    default:
      return { contract: speciesContractWithSigner, iface: speciesContractIFace };
    }
  },
  async requestWithSigner(
    connectionType,
    buyerAddress,
    functionName,
    params = [],
    contractType = null,
    price = 0,
    waitReceipt = false,
  ) {
    if (connectionType === ConnectionType.Metamask) {
      return this.requestWithSignerMetamask(functionName, params, contractType, price, waitReceipt);
    } else if (connectionType === ConnectionType.WalletConnect) {
      return this.requestWithSignerWalletConnect(buyerAddress, functionName, params, contractType, price, waitReceipt);
    }
  },
  async requestWithSignerMetamask(functionName, params, contractType, price, waitReceipt) {
    const { contract } = this.getContractWithSigner(contractType);
    const override = {};

    if (price) {
      override.value = price.toString();

      const estimatedGas = await contract.estimateGas[functionName](...params, override);
      override.gasLimit = Math.round(estimatedGas * 2);
    }

    const tx = await contract[functionName](...params, override);

    if (waitReceipt) {
      await tx.wait();
    }

    return tx.hash;
  },
  async requestWithSignerWalletConnect(buyerAddress, functionName, params, contractType, price, waitReceipt) {
    const { contract, iface } = this.getContractWithSigner(contractType);
    const tx = {
      from: buyerAddress,
      to: contract.address,
      data: iface.encodeFunctionData(functionName, params),
    };

    if (price) {
      tx.value = price.toString();
    }

    const { hash } = await connector.web3Provider.getSigner().sendTransaction(tx);

    if (waitReceipt) {
      let timerId;

      return new Promise((res, rej) => {
        timerId = setInterval(async () => {
          const receipt = await web3.getTransactionReceipt(hash);

          if (receipt) {
            clearInterval(timerId);
            if (receipt.status) {
              res(hash);
            } else {
              rej(new Error(`The contract execution was not successful, check your transaction ${hash}!`));
            }
          }
        }, 2000);
      });
    }

    return hash;
  },
  async setApprovalForAll(connectionType, walletAddress, mintType) {
    const address = mintType === MintType.Dog
      ? process.env.REACT_APP_DOG_ADDRESS
      : process.env.REACT_APP_PIGEON_ADDRESS;
    await ContractApi.requestWithSigner(
      connectionType,
      walletAddress,
      'setApprovalForAll',
      [address, true],
      null,
      0,
      true,
    );
  },
  async contractMint(connectionType, walletAddress, tokenId, mintType) {
    return await this.requestWithSigner(
      connectionType,
      walletAddress,
      'mint',
      [tokenId],
      mintType,
    );
  },
};

export default ContractApi;
