import { ethers } from 'ethers';

// abis
import speciesAbi from '../assets/abis/gutter-species.json';
import dogProdAbi from '../assets/abis/gutter-dog.json';
import pigeonProdAbi from '../assets/abis/gutter-pigeon.json';

// instances
import { signer, web3MainNet } from './ethers';

const ContractType = {
  contractPigeon: 3,
  contractDog: 4,
};

const speciesContract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, speciesAbi, web3MainNet);
const speciesContractWithSigner = speciesContract.connect(signer);
const speciesContractIFace = new ethers.utils.Interface(speciesAbi);

const dogContract = new ethers.Contract(process.env.REACT_APP_DOG_ADDRESS, dogProdAbi, web3MainNet);
const dogContractWithSigner = dogContract.connect(signer);
const dogContractIFace = new ethers.utils.Interface(dogProdAbi);

const pigeonContract = new ethers.Contract(process.env.REACT_APP_PIGEON_ADDRESS, pigeonProdAbi, web3MainNet);
const pigeonContractWithSigner = pigeonContract.connect(signer);
const pigeonContractIFace = new ethers.utils.Interface(pigeonProdAbi);

export {
  ContractType,
  speciesContract,
  speciesContractWithSigner,
  speciesContractIFace,
  dogContract,
  dogContractWithSigner,
  dogContractIFace,
  pigeonContract,
  pigeonContractWithSigner,
  pigeonContractIFace,
};
