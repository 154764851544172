import { useDispatch } from 'react-redux';

// store
import { closeErrorModal } from '../../../store/client';

// components
import StyledModal from '../../common/StyledModal/StyledModal';

import styles from './ErrorPopup.module.css'

export default function ErrorPopup({ error, ...params }) {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(closeErrorModal());
  };

  return (
    <StyledModal {...params} title="Error" handleClose={closeModal}>
      <p className={styles.error_message}>{ error }</p>
    </StyledModal>
  );
}
