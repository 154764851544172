import { useDispatch, useSelector } from 'react-redux';

import {
  MintProcess,
  closeCurrentModal,
  connectLedger,
  connectMetamask,
  selectCurrentModal,
} from '../../../../store/client';

import StyledButton from '../../../common/StyledButton/StyledButton';
import StyledModal from '../../../common/StyledModal/StyledModal';

import styles from './WalletModal.module.css';

export default function WalletModal() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);

  const closeModal = () => {
    dispatch(closeCurrentModal());
  };

  const connectToMetamask = () => {
    dispatch(connectMetamask());
  };

  const connectToLedger = () => {
    dispatch(connectLedger());
  }

  return (
    <StyledModal
      title={'Choose wallet'}
      isOpen={currentModal === MintProcess.wallet}
      handleClose={closeModal}
    >
      <div className={styles.wallet_box}>
        <StyledButton
          handleClick={connectToMetamask}
          className={styles.wallet}>
          Metamask
        </StyledButton>
        <StyledButton
          handleClick={connectToLedger}
          className={styles.wallet}
        >
          WalletConnect
        </StyledButton>
      </div>
    </StyledModal>
  );
}
