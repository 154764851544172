import { useDispatch } from 'react-redux';

import {
  MintType,
  openMintModal,
} from '../../../store/client';

import CARD from '../../../assets/images/card.jpg';

import StyledButton from '../../common/StyledButton/StyledButton';
import WalletModal from '../Header/WalletModal/WalletModal';
import MintBox from './MintBox/MintBox';

import styles from './Content.module.css';

export default function Content() {
  const dispatch = useDispatch();

  const handleGutterDog = () => {
    dispatch(openMintModal(MintType.Dog));
  };

  const handleGutterPigeon = () => {
    dispatch(openMintModal(MintType.Pigeon));
  };

  return (
    <main className={styles.main}>
      <div className={styles.content_container}>
        <div className={styles.left}>
          <h1 className={styles.title}>
            <span>GUTTER PIGEONS &</span>
            <span>GUTTER DOGS</span>
            <span>HAVE ARRIVED</span>
          </h1>
          <p className={styles.description}>
            The Gutter is growing by not ONE but TWO new species! Gutter Pigeons are redeemable via
            Gutter Species Mint Pass #3 and Gutter Dogs are redeemable via Gutter Species Mint Pass
            #4. Gutter Species Mint Passes are available for purchase at&nbsp;
            <a
              className={styles.opensea_href}
              href="https://opensea.io/collection/gutter-species-mint-pass"
            >
              Opensea
            </a>
          </p>
          <div className={styles.faq_link_wrapper}>
            <a className={styles.faq_link} href="#faq">Visit FAQ</a>
          </div>
          <div className={styles.btn_box}>
            <StyledButton
              className={styles.get_gutter_btn}
              handleClick={handleGutterPigeon}>
              Redeem Species #3
            </StyledButton>
            <StyledButton
              className={styles.get_gutter_btn}
              handleClick={handleGutterDog}>
              Redeem Species #4
            </StyledButton>
          </div>
        </div>
        <div className={styles.right}>
          <img className={styles.card_img} src={CARD} alt=""/>
        </div>
      </div>
      <MintBox/>
      <WalletModal/>
    </main>
  );
}
