import { useDispatch, useSelector } from 'react-redux';

import {
  MintProcess,
  closeCurrentModal,
  selectCurrentMintSide,
  selectCurrentModal,
  selectTransactionHash,
} from '../../../../store/client';

import StyledModal from '../../../common/StyledModal/StyledModal';
import StyledButton from '../../../common/StyledButton/StyledButton';
import MintModal from './MintModal/MintModal';

import styles from './MintBox.module.css';

export default function MintBox() {
  const dispatch = useDispatch();
  const currentModal = useSelector(selectCurrentModal);
  const currentMintSide = useSelector(selectCurrentMintSide);
  const transactionHash = useSelector(selectTransactionHash);

  const closeModal = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <>
      <StyledModal
        title={'Gutter ' + currentMintSide}
        isOpen={currentModal === MintProcess.mint}
        handleClose={closeModal}
      >
        {currentModal === MintProcess.mint ? <MintModal/> : null}
      </StyledModal>

      <StyledModal
        title={'Thank you!'}
        isOpen={currentModal === MintProcess.success}
        handleClose={closeModal}
      >
        <p className={styles.info_text}>
          Your transaction is now pending. You can check the transaction status on Etherscan.
        </p>

        <a
          href={`${process.env.REACT_APP_ETHERSCAN}/tx/${transactionHash}`}
          className={styles.info_link}
        >
          Your transaction status
        </a>

        <p className={styles.info_text}>
          Once completed, your NFT will be viewable on OpenSea
        </p>

        <StyledButton
          href={`${process.env.REACT_APP_OPEN_SEA}/account`}
          target="_blank"
          className={styles.styled_button}
          isLink
        >
          OpenSea
        </StyledButton>
      </StyledModal>
    </>
  );
}
