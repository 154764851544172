import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectLoading } from '../../../store/client';
import styles from './StyledButton.module.css';

export default function StyledButton(
  {
    children,
    className,
    isLink = false,
    fullWidth = false,
    type = 'button',
    disable=false,
    handleClick,
    ...params
  }) {
  const loading = useSelector(selectLoading);

  const Component = isLink ? 'a' : 'button';
  return (
    <Component
      {...params}
      type={isLink ? null : type}
      className={classNames(styles.styled_button, { 'full-width': fullWidth }, className)}
      onClick={handleClick}
      disabled={isLink ? null : (disable || loading)}
    >
      {children}
    </Component>
  );
}
