// import WalletConnect from '@walletconnect/client';
// import QRCodeModal from '@walletconnect/qrcode-modal';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { ethers } from 'ethers';
import { metamaskProvider } from './ethers';

// export const connector = new WalletConnect({
//   bridge: 'https://bridge.walletconnect.org',
//   qrcodeModal: QRCodeModal,
// });

const connector = {
  provider: null,
  web3Provider: null,
}

async function getConnector() {
  connector.provider = await EthereumProvider.init({
    projectId: process.env.REACT_APP_WC_PROJECT_ID,
    chains: [process.env.REACT_APP_MAIN_NET],
    showQrModal: true,
  });

  connector.web3Provider = new ethers.providers.Web3Provider(connector.provider);
}

getConnector();

export { connector };
