import { useState } from 'react';
import StyledModal from '../../common/StyledModal/StyledModal';
import styles from './Footer.module.css';

export default function Footer() {
  const [disclaimerModalState, setDisclaimersModalState] = useState(false);

  const openDisclaimerModal = () => {
    setDisclaimersModalState(true);
  };

  const closeDisclaimerModal = () => {
    setDisclaimersModalState(false);
  };

  return (
    <footer className={styles.footer}>
      <section className={styles.faq} id="faq">
        <h3 className={styles.faq_title}>
          DAPP FAQ
        </h3>
        <h4 className={styles.faq_sub_title}>
          Welcome to the Gutter Cat Gang! We are beyond excited to have you.
        </h4>
        <p className={styles.faq_p_margin}>Mint Pass Redemption Steps:</p>
        <p>
          <strong>Step 1 (Connect Wallet):</strong> Connect your wallet to the dapp. For the most
          optimal experience, please use your Metamask wallet in Google Chrome on a desktop or
          laptop.
        </p>
        <p>
          <strong>Step 2 (Set Approval):</strong> The first time you redeem a species #3 or #4 mint
          pass,
          you will be asked to set approval for the contract to burn the mint pass in exchange
          for minting a Gutter Pigeon or Gutter Dog.
        </p>
        <p>
          <strong>Step 3 (Mint):</strong> After approving the burn, you will then be prompted to
          mint
          your Gutter Pigeon or Gutter Dog.
        </p>
        <p>
          <strong>Please Note:</strong> while the mint transaction is pending you may still see your
          Gutter Species Mint Pass in your wallet. Do not try to redeem your mint pass twice or your
          second transaction will fail and you will waste gas.
        </p>
        <h4 className={styles.faq_redemption_sub_title}>
          Mint Pass Redemption FAQ:
        </h4>
        <ul>
          <li><p>
            Gutter Species Mint Passes are available to purchase on the secondary market on
            Opensea:&nbsp;
            <a
              className={styles.text_link}
              href="https://opensea.io/collection/gutter-species-mint-pass"
            >
              https://opensea.io/collection/gutter-species-mint-pass
            </a>
          </p></li>
          <li><p>
            The Official Opensea Collection for Redeemed Gutter Pigeons is:&nbsp;
            <a
              className={styles.text_link}
              href="https://opensea.io/collection/gutterpigeons"
            >
              https://opensea.io/collection/gutterpigeons
            </a>
          </p></li>
          <li><p>
            The Official Opensea Collection for Redeemed Gutter Dogs is:&nbsp;
            <a
              className={styles.text_link}
              href="https://opensea.io/collection/gutterdogs"
            >
              https://opensea.io/collection/gutterdogs
            </a>
          </p></li>
          <li><p>
            The Gutter Pigeons Smart Contract is:&nbsp;
            <a
              className={styles.text_link}
              href="https://etherscan.io/address/0x950b9476a4de757BB134483029AC4Ec17E739e3A"
            >
              https://etherscan.io/address/0x950b9476a4de757BB134483029AC4Ec17E739e3A
            </a>
          </p></li>
          <li><p>
            The Gutter Dogs Smart Contract is:&nbsp;
            <a
              className={styles.text_link}
              href="https://etherscan.io/address/0x6E9DA81ce622fB65ABf6a8d8040e460fF2543Add"
            >
              https://etherscan.io/address/0x6E9DA81ce622fB65ABf6a8d8040e460fF2543Add
            </a>
          </p></li>
          <li><p>
            Gutter Pigeons are Species #3
          </p></li>
          <li><p>
            Gutter Dogs are Species #4
          </p></li>
          <li><p>
            There are 3,000 Gutter Pigeons
          </p></li>
          <li><p>
            There are 3,000 Gutter Dogs
          </p></li>
          <li><p>
            Gutter Species Mint Pass redemption will remain open indefinitely. For this reason, we
            encourage you to wait until gas is low to redeem your mint pass.
          </p></li>
          <li><p>
            Mint passes will be burned and then you will be able to mint a Gutter Pigeon or Dog
          </p></li>
          <li><p>
            Gutter Pigeons and Gutter Dogs were algorithmically generated and then randomly assigned
            to IDs. Your mint pass ID does NOT correspond to a specific species ID. Species IDs will
            be minted consecutively.
          </p></li>
          <li><p>
            Ledger wallet owners can use this dapp with WalletConnect and LedgerLive.
          </p></li>
          <li><p>
            Trezor wallet owners can use this dapp with Metamask downgraded to 9.8.4. This is
            because
            the latest Trezor firmware version is not fully compatible with the latest Metamask
            version.
          </p></li>
          <li><p>
            We encourage you to use this dapp on a desktop/laptop computer connected to Metamask
            for the best experience.
          </p></li>
          <li><p>
            Looking for more info? Visit&nbsp;
            <a
              className={styles.text_link}
              href="https://guttercatgang.com/"
            >
              GCG Website
            </a>
          </p></li>
          <li><p>
            Gutter Pigeons, Gutter Dogs, and Gutter Rats are the most affordable membership level to
            join the Gutter Cat Gang! Each species will enjoy unique utility and gang perks in
            future
            merch collabs, IRL events, airdrops, etc. The Gutter Cats represent our premium
            membership
            level and will receive the highest level of utility and perks within the gang. We are
            very
            excited to have you join! #GangGang
          </p></li>
        </ul>
      </section>
      <div className={styles.content_container}>
        <div className={styles.left}>
          <h2 className={styles.left_title}>Gutter Cat Gang</h2>
          <div className={styles.link_box}>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://guttercatgang.com"
            >
              Gutter Cat Gang
            </a>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://guttercatgang.com/gutter-cat-gang-terms-conditions/"
            >
              Terms & Disclaimers
            </a>
            {/*<button*/}
            {/*  className={styles.link}*/}
            {/*  onClick={openDisclaimerModal}*/}
            {/*>*/}
            {/*  Terms & Disclaimers*/}
            {/*</button>*/}
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_ETHERSCAN}/address/${process.env.REACT_APP_PIGEON_ADDRESS}`}
            >
              Gutter Pigeons Smart Contract
            </a>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_ETHERSCAN}/address/${process.env.REACT_APP_DOG_ADDRESS}`}
            >
              Gutter Dogs Smart Contract
            </a>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <h3 className={styles.copyright_text}>
          Copyright © 2023. All rights reserved
        </h3>
      </div>
      <StyledModal
        title={'Disclaimers'}
        isOpen={disclaimerModalState}
        handleClose={closeDisclaimerModal}
      >
        <div className={styles.disclaimer_text_box}>
          <p className={styles.disclaimer_text}>
            You are solely responsible for your own conduct while accessing or using the Site, and
            for any consequences thereof. You agree to use the Site only for purposes that are
            legal,
            proper and in accordance with these Terms and any applicable laws or regulations. If you
            elect to redeem a Gutter Pigeon or Gutter Dog through the Site, any financial
            transactions
            that you engage in will be conducted solely through the Ethereum network. We will have
            no
            insight into or control over these payments or transactions, nor do we have the ability
            to
            reverse any transactions. We will have no liability to you or to any third party for any
            claims or damages that may arise as a result of any transactions that you engage or any
            other transactions that you conduct via the Ethereum network. Ethereum requires the
            payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the
            Ethereum network. The Gas Fee funds the network of computers that run the decentralized
            Ethereum network. This means that you will need to pay a Gas Fee for each transaction.
          </p>
          <br />
          <p className={styles.disclaimer_text}>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE IS AT YOUR
            SOLE
            RISK, AND THAT THE SITE IS PROVIDED {'"AS IS" AND "AS AVAILABLE"'} WITHOUT
            WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
            APPLICABLE
            LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING
            THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART
            CONTRACT,
            OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY.
            WITHOUT
            LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND
            LICENSORS
            DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET
            YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY,
            SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE,
            (III) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE
            SITE
            ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE
            WHEN YOU USE THE SITE WILL BE SECURE. YOU ACCEPT THE INHERENT SECURITY RISKS OF
            PROVIDING
            INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR
            RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFULL MISCONDUCT.
            WE
            WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR
            USE OF THE ETHEREUM NETWORK NOR DO WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES
            REGARDING ANY SMART CONTRACTS. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO
            YOU
            OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
            DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
            INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
            INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT
            OF
            SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU
            FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR
            USE
            OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT,
            TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE AMOUNTS YOU
            ACTUALLY
            PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.YOU
            ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE
            TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH
            HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
          </p>
        </div>
      </StyledModal>
    </footer>
  );
}
