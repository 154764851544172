import { MintType } from '../store/client';

function contractActivityCheck(mintType, { dogPaused, pigeonPaused }) {
  const isPaused = mintType === MintType.Dog ? dogPaused : pigeonPaused;

  if (isPaused) {
    return 'Minting is paused';
  }

  return null;
}

export default contractActivityCheck;
