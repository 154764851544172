import Header from './Header/Header';
import Footer from './Footer/Footer';
import Content from './Content/Content';

export default function AppView() {
  return (
    <>
      <Header/>
      <Content/>
      <Footer/>
    </>
  );
}
